import { Business, MoreHoriz } from "@mui/icons-material";
import { Avatar, Box, capitalize, Grid, IconButton, Menu, MenuItem, Skeleton, Stack, TableCell, TableRow, Typography } from "@mui/material";
import StatusPill from "components/Utilities/StatusPill";
import { extractUserIdFromDociId, formatDate } from "helpers/func";
import React, { useMemo, useState } from "react";
import SegmentedControl from "./SegmentedControl";
import TableLayout from "components/layouts/TableLayout";
import { Loader, Modals, Search } from "components/Utilities";
import { EnhancedTable, NoData } from "components/layouts";
import { microinsuranceBusinessEmployeesHeader } from "components/Utilities/tableHeaders";
import { defaultPageInfo } from "helpers/mockData";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { getBusiness, getEmployeesByPlanType, getEmployeesByPlanTypeCount, getSubscription } from "components/graphQL/useQuery";
import { useQuery } from "@apollo/client";

const MicroInsuranceBusiness = () => {
  // get id from param
  const { id } = useParams();
  const { data: _businessData, loading: businessDataLoading } = useQuery(getBusiness, {
    variables: {
      id
    }
  })
  const businessData = _businessData?.businesses?.data?.[0];

  const { data: _subscriptionData, loading: subscriptionDataLoading } = useQuery(getSubscription, {
    variables: {
      email: businessData?.email
    },
    skip: !businessData
  });
  const subscriptionData = _subscriptionData?.getSubscription;
  const subscription = {
    name: subscriptionData?.planId?.name,
    status: subscriptionData?.status,
    expiryDate: subscriptionData?.subscriptionExpiresAt,
    duration: subscriptionData?.planId?.duration
  }

  const { data: _employeesOnFamilyPlanCountData } = useQuery(getEmployeesByPlanTypeCount, {
    variables: {
      businessId: id,
      type: "family"
    },
  });
  const { data: _employeesOnIndividualPlanCountData } = useQuery(getEmployeesByPlanTypeCount, {
    variables: {
      businessId: id,
      type: "individual"
    },
  });
  const employeesOnFamilyPlanCount = _employeesOnFamilyPlanCountData?.employees?.data?.length;
  const employeesOnIndividualPlanCount = _employeesOnIndividualPlanCountData?.employees?.data?.length;

  const [currentTab, setCurrentTab] = useState("family");
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const tabs = useMemo(() => ([
    { label: "Employees on Family Plan", value: "family", count: employeesOnFamilyPlanCount },
    { label: "Employees on Individual Plan", value: "individual", count: employeesOnIndividualPlanCount }
  ]), [employeesOnFamilyPlanCount, employeesOnIndividualPlanCount]);

  const [page, setPage] = useState(1);
  const { data: _employeesData, loading: employeesDataLoading, error: employeesDataError, refetch: refetchEmployeesData } = useQuery(getEmployeesByPlanType, {
    variables: {
      businessId: id,
      type: currentTab,
      page
    },
  })
  const employeesData = _employeesData?.employees?.data;
  const employeesDataPageInfo = _employeesData?.employees?.pageInfo || defaultPageInfo;

  const business = {
    businessOwnerName: `${businessData?.profileId?.firstName} ${businessData?.profileId?.lastName}`,
    image: businessData?.image,
    dateCreated: businessData?.createdAt,
    email: businessData?.email,
    phoneNumber: businessData?.profileId?.phoneNumber,
    businessName: businessData?.name,
    industry: businessData?.industry,
    noOfEmployees: businessData?.noOfEmployees,
    employeesOnFamilyPlan: employeesOnFamilyPlanCount,
    employeesOnIndividualPlan: employeesOnIndividualPlanCount,
    preferredPlan: subscription.name,
    planDuration: subscription.duration
  };

  const [searchString, setSearchString] = useState("");
  const handleSearch = () => {
    setPage(1);
    refetchEmployeesData({ search: searchString });
  };

  return (
    <>
      {/* Business and Active Plan Info */}
      <Grid container spacing={2} mt={3} justifyContent="space-between">
        {businessDataLoading ? (
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton variant="circular" width={80} height={80} />
            <Stack spacing={1} ml={2}>
              <Skeleton variant="text" width={160} height={26} />
              <Skeleton variant="text" width={120} height={22} />
            </Stack>
          </Grid>
        ) : (
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={business.image} alt={name} sx={{
              width: 80, height: 80, marginRight: 3, border: "1px solid #CED0D3",
                backgroundColor: "#CED0D3"
            }}>
              <Business />
            </Avatar>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography variant="h3">
                {business.businessName}
              </Typography>
              <Typography
                color="common.blue"
                fontSize="13px"
                sx={{ cursor: 'pointer' }}
                onClick={() => setShowDetailsModal(true)}
              >
                More Details
              </Typography>
            </Box>
          </Grid>
        )}

        {(businessDataLoading || subscriptionDataLoading) ? (
          <Grid item display="flex" flexDirection="column" gap={1} justifyContent={{ sm: "right" }}
          >
            <Skeleton variant="text" width={220} height={18} />
            <Skeleton variant="text" width={180} height={20} />
            <Skeleton variant="text" width={120} height={18} />
          </Grid>
        ) : <Grid item display="flex" flexDirection="column" gap={2} justifyContent={{ sm: "right" }}
        >
          <Box display="flex" gap={2} alignItems="center">
            <Typography>
              {subscription.name}
            </Typography>
            <StatusPill
              label={capitalize(subscription.status || '')}
              type={
                subscription.status === "active" ? "success" : "unknown"
              }
            />
          </Box>

          <Box>
            <Typography
              fontSize="13px"
              fontWeight={400}
              textAlign={{ sm: "right" }}
            >
              This plan <Box component="span" color="#BF1A2F">expires</Box> on <Box component="span" color="common.blue" fontSize="16px">{subscription.expiryDate && formatDate(subscription.expiryDate, "d MMMM, yyyy")}</Box>
            </Typography>
          </Box>

          <Typography textTransform="capitalize"
            textAlign={{ sm: "right" }}
          >
            {subscription.duration}
          </Typography>
        </Grid>}
      </Grid>

      <BusinessDetailsModal
        isOpen={showDetailsModal}
        onClose={() => setShowDetailsModal(false)}
        data={business}
      />

      <Box
        sx={{
          mt: 3,
          backgroundColor: "white",
          padding: "16px 32px",
          borderRadius: "24px",
          border: "1px solid rgba(128, 128, 128, 0.25)"
        }}
      >
        <SegmentedControl
          tabs={tabs}
          value={currentTab}
          onChange={(_, value) => setCurrentTab(value)}
        />
      </Box>

      {/* Table */}
      <Box mt={3}>
        <TableLayout
          search={
            <Search
              placeholder="Search..."
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              onClickSearchBtn={handleSearch}
            />
          }
        >
          {employeesDataLoading ? <Loader /> : !employeesData?.length ? <NoData error={employeesDataError} /> : <Grid
            container
            item
            direction="column"
            overflow="hidden"
            maxWidth={{ md: "100%", sm: "100%", xs: "100%" }}
          >
            <EnhancedTable
              headCells={microinsuranceBusinessEmployeesHeader}
              rows={employeesData}
              dataPageInfo={employeesDataPageInfo}
              handlePagination={(page) => setPage(page)}
            >
              {employeesData.map((e, i) => (
                <EmployeeRow
                  key={i}
                  {...e}
                />
              ))}
            </EnhancedTable>

          </Grid>}
        </TableLayout>
      </Box>


    </>
  );
};

export default MicroInsuranceBusiness;

const EmployeeRow = (data) => {
  const history = useHistory();
  const cellId = `employee-cell`;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [{
    text: "View profile",
    onClick: () => history.push(`/plans/microinsurance/employee/${data?._id}`)
  }]

  return (
    <TableRow
      hover
    >
      <TableCell
        id={cellId}
        scope="row"
        align="left"
      >
        {data?.profileId?.hmoId || '-'}
      </TableCell>
      <TableCell
        id={cellId}
        scope="row"
        align="left"
      >
        {data?.firstName + " " + data?.lastName}
      </TableCell>
      <TableCell
        id={cellId}
        scope="row"
        align="left"
      >
        {data?.email}
      </TableCell>
      <TableCell
        id={cellId}
        scope="row"
        align="left"
      >
        <StatusPill
          label={_.capitalize(data?.status)}
          type={{
            "accepted": "success",
            "pending": "warning",
          }[data?.status]}
        />
      </TableCell>
      <TableCell
        id={cellId}
        scope="row"
        align="left"
      >
        <IconButton
          onClick={handleMenuOpen}
        >
          <MoreHoriz sx={{ color: "common.blue" }} />
        </IconButton>

        <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                item.onClick();
                handleMenuClose();
              }}
            >
              {item.text}
            </MenuItem>
          ))}
        </Menu>
      </TableCell>
    </TableRow>
  )
}

const BusinessDetailsModal = ({ isOpen, onClose, data }) => {
  return (
    <Modals
      isOpen={isOpen}
      title="Business Details"
      width={{ xs: "90vw", sm: "70vw", lg: "580px" }}
      handleClose={onClose}
    >
      <Box paddingTop={6}>
        <Avatar src={data.image} alt={name} sx={{
          width: 80, height: 80, marginRight: 3, border: "1px solid #CED0D3",
        }}>
          <Business sx={{ fontSize: '48px' }} />
        </Avatar>
        <Grid container direction="column" spacing={2} mt={3}>
          <ListItem title="Business name" value={data.businessName} />
          <ListItem title="Business owner" value={data.businessOwnerName} />
          <ListItem title="Email address" value={data.email} />
          <ListItem title="Phone number" value={data.phoneNumber} />
          <ListItem title="Industry" value={data.industry} />
          <ListItem title="Number of employees" value={data.noOfEmployees} />
          <ListItem title="Employees on family plan" value={data.employeesOnFamilyPlan} />
          <ListItem title="Employees on individual plan" value={data.employeesOnIndividualPlan} />
          <ListItem title="Preferred plan" value={data.preferredPlan} />
          <ListItem title="Plan duration" value={_.capitalize(data.planDuration)} />
        </Grid>
      </Box>
    </Modals>
  );
};


const ListItem = ({ title, value }) => {

  return (
    <Grid item container justifyContent="space-between">
      <Grid color="rgba(93,98,108,1)" item>{title}</Grid>
      <Grid item color="rgba(12,19,34,1)">{value}</Grid>
    </Grid>
  )
}