import * as React from "react";
const MoneyReceiveIcon = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.0801 8.33331H26.8934C26.6401 7.97331 26.3734 7.63997 26.0934 7.30664L25.0801 8.33331Z"
      fill="#8AD6AA"
    />
    <path
      d="M24.6927 5.89375C24.3594 5.61375 24.026 5.34708 23.666 5.09375V6.90708L24.6927 5.89375Z"
      fill="#8AD6AA"
    />
    <path
      d="M26.1067 7.30659L30.04 3.37326C30.4267 2.98659 30.4267 2.34659 30.04 1.95992C29.6534 1.57326 29.0134 1.57326 28.6267 1.95992L24.6934 5.89326C25.2 6.33326 25.6667 6.81326 26.1067 7.30659Z"
      fill="#8AD6AA"
    />
    <path
      d="M23.6664 4C23.6664 3.45333 23.213 3 22.6664 3C22.133 3 21.7064 3.42667 21.6797 3.94667C22.373 4.28 23.0397 4.65333 23.6664 5.09333V4Z"
      fill="#8AD6AA"
    />
    <path
      d="M28.9992 9.33398C28.9992 8.78732 28.5459 8.33398 27.9992 8.33398H26.8926C27.3326 8.96065 27.7192 9.62732 28.0392 10.3207C28.5726 10.294 28.9992 9.86732 28.9992 9.33398Z"
      fill="#8AD6AA"
    />
    <path
      d="M17 19.6671H17.4C17.92 19.6671 18.3333 19.2004 18.3333 18.6271C18.3333 17.9071 18.1333 17.8004 17.68 17.6404L17 17.4004V19.6671Z"
      fill="#8AD6AA"
    />
    <path
      d="M28.0527 10.3193C28.0394 10.3193 28.026 10.3327 27.9993 10.3327H22.666C22.5327 10.3327 22.4127 10.306 22.2793 10.2527C22.0393 10.146 21.8393 9.95935 21.7327 9.70602C21.6927 9.58602 21.666 9.46602 21.666 9.33268V3.99935C21.666 3.98602 21.6793 3.97268 21.6793 3.94602C19.946 3.13268 18.026 2.66602 15.9993 2.66602C8.63935 2.66602 2.66602 8.63935 2.66602 15.9993C2.66602 23.3594 8.63935 29.3327 15.9993 29.3327C23.3594 29.3327 29.3327 23.3594 29.3327 15.9993C29.3327 13.9727 28.866 12.0527 28.0527 10.3193ZM18.3327 15.7593C19.186 16.0527 20.3327 16.6793 20.3327 18.6393C20.3327 20.306 19.0127 21.6793 17.3993 21.6793H16.9993V22.0127C16.9993 22.5593 16.546 23.0127 15.9993 23.0127C15.4527 23.0127 14.9993 22.5593 14.9993 22.0127V21.6793H14.8927C13.1193 21.6793 11.666 20.186 11.666 18.346C11.666 17.786 12.1193 17.3327 12.666 17.3327C13.2127 17.3327 13.666 17.786 13.666 18.3327C13.666 19.066 14.2127 19.666 14.8927 19.666H14.9993V16.706L13.666 16.2393C12.8127 15.946 11.666 15.3194 11.666 13.3594C11.666 11.6927 12.986 10.3193 14.5993 10.3193H14.9993V9.99935C14.9993 9.45268 15.4527 8.99935 15.9993 8.99935C16.546 8.99935 16.9993 9.45268 16.9993 9.99935V10.3327H17.106C18.8793 10.3327 20.3327 11.826 20.3327 13.666C20.3327 14.2127 19.8793 14.666 19.3327 14.666C18.786 14.666 18.3327 14.2127 18.3327 13.666C18.3327 12.9327 17.786 12.3327 17.106 12.3327H16.9993V15.2927L18.3327 15.7593Z"
      fill="#8AD6AA"
    />
    <path
      d="M13.666 13.374C13.666 14.094 13.866 14.2007 14.3193 14.3607L14.9993 14.6007V12.334H14.5993C14.0927 12.334 13.666 12.8007 13.666 13.374Z"
      fill="#8AD6AA"
    />
  </svg>
);
export default MoneyReceiveIcon;
