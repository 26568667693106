import React, { useEffect, useMemo, useState } from "react";
import useAlert from "hooks/useAlert";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useTheme, makeStyles } from "@mui/styles";
import { defaultPageInfo } from "helpers/mockData";
import MainModal from "components/modals/MainModal";
import { CustomButton } from "components/Utilities";
import { Avatar, Grid, Typography } from "@mui/material";
import ConfirmModal from "components/modals/ConfirmModal";
import { uploadFile } from "helpers/filterHelperFunctions";
import SingleHMOTable from "components/Tables/SingleHMOTable";
import { uploadHMOEnrollees } from "components/graphQL/Mutation";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { getEnrolles, getProviderLogo } from "components/graphQL/useQuery";
import AddEditHMOEnrolleeModal from "components/modals/AddEditHMOEnrolleeModal";
import { UploadEnrolleeListForm } from "components/Forms/UploadEnrolleeListForm";

const useStyles = makeStyles(() => ({
  img: {
    "& .MuiAvatar-img": {
      objectFit: "contain",
    },
  },
}));

const SingleHMO = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { hmoId } = useParams();
  const { displayAlert, getErrorMsg } = useAlert();
  const [hmoEnrollees, setHmoEnrollees] = useState([]);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [uploadEnrollees] = useMutation(uploadHMOEnrollees);
  const [uploadListModal, setUploadListModal] = useState(false);
  const [addEnrolleeModal, setAddEnrolleeModal] = useState(false);
  const [confirmUploadModal, setConfirmUploadModal] = useState(false);
  const { data: providerData } = useQuery(getProviderLogo, {
    variables: { providerId: hmoId },
  });

  const [
    fetchEnrollees,
    { error, loading, refetch, variables, networkStatus },
  ] = useLazyQuery(getEnrolles, {
    notifyOnNetworkStatusChange: true,
  });

  const enrolleesParams = {
    hmoEnrollees,
    setHmoEnrollees,
    pageInfo,
    setPageInfo,
    fetchEnrollees,
    error,
    loading,
    refetch,
    variables,
    networkStatus,
  };

  const { name, icon } = useMemo(
    () => providerData?.getProvider || {},
    [providerData]
  );

  const fetchData = (fetch) => {
    fetch({
      variables: {
        providerId: hmoId,
        first: 10,
      },
    })
      .then(({ data }) => {
        setHmoEnrollees(data?.getEnrollees?.data || []);
        setPageInfo(data?.getEnrollees?.pageInfo || {});
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData(fetchEnrollees);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchEnrollees, hmoId]);

  const darkButton = {
    background: theme.palette.primary.main,
    hover: theme.palette.primary.light,
    active: theme.palette.primary.dark,
  };

  const lightButton = {
    background: "#fff",

    hover: theme.palette.primary.light,
    active: theme.palette.primary.dark,
  };

  return (
    <Grid>
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
        marginBottom="2rem"
        marginTop="2rem"
      >
        <Grid item>
          <Grid container alignItems="center" columnGap={2}>
            {icon && (
              <Avatar
                className={classes.img}
                imgProps={{
                  height: "50px",
                  width: "50px",
                }}
                src={icon}
                alt={icon}
                sx={{
                  height: "50px",
                  width: "50px",
                  objectFit: "contain !important",
                }}
              />
            )}
            <Typography variant="h1">{name}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container columnGap={2}>
            <CustomButton
              startIcon={<AddIcon />}
              title="Upload List"
              type={lightButton}
              textColor="#2D2F39"
              textColorOnHover="#ffffff"
              onClick={() => setConfirmUploadModal(true)}
            />
            <CustomButton
              startIcon={<AddIcon />}
              title="Add  Enrollee"
              type={darkButton}
              onClick={() => setAddEnrolleeModal(true)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <SingleHMOTable enrolleesParams={enrolleesParams} />
      </Grid>

      {/* MODALS */}
      <Grid>
        {/* ADD ENROLLEE MODAL */}
        <AddEditHMOEnrolleeModal
          isOpen={addEnrolleeModal}
          setIsOpen={setAddEnrolleeModal}
          refetchData={() =>
            refetch({
              variables: {
                providerId: hmoId,
                first: 10,
              },
            })
              .then(({ data }) => {
                setHmoEnrollees(data?.getEnrollees?.data || []);
                setPageInfo(data?.getEnrollees?.pageInfo || {});
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error);
              })
          }
        />

        {/* ======== CONFIRM UPLOAD ENROLLEE MODAL ========= */}
        <ConfirmModal
          open={confirmUploadModal}
          setOpen={setConfirmUploadModal}
          title="Confirm Upload Enrollee list"
          confirmationMsg="Uploading a new Enrollee JSON file will overwrite the current file."
          onConfirm={() => {
            setConfirmUploadModal(false);
            setUploadListModal(true);
          }}
          onCancel={() => {
            setConfirmUploadModal(false);
          }}
        />

        {/* UPLOAD LIST MODAL */}
        <MainModal isOpen={uploadListModal} setIsOpen={setUploadListModal}>
          <UploadEnrolleeListForm
            onSubmit={async (values) => {
              const throwError = () =>
                displayAlert(
                  "error",
                  "Something went wrong while uploading file."
                );
              try {
                const { file } = values;
                const fileUrl = await uploadFile(file);

                if (typeof fileUrl !== "string") {
                  throwError();
                  return;
                }

                const { data } = await uploadEnrollees({
                  variables: {
                    planId: values?.planId,
                    fileUrl: fileUrl,
                    providerId: hmoId,
                    replace: values?.replace || false,
                  },
                });

                if (!data) {
                  throwError();
                  return;
                }

                const enrolleesAdded =
                  data?.uploadEnrollees?.result?.totalInserted;

                displayAlert(
                  "success",
                  `${enrolleesAdded && enrolleesAdded} Enrollees Added.`
                );
                fetchData(refetch);
                setUploadListModal(false);
              } catch (error) {
                // eslint-disable-next-line no-console
                console.error("Error from onSubmit Test JSON file", error);
                const errMsg = getErrorMsg(error);
                displayAlert("error", errMsg);
              }
            }}
          />
        </MainModal>
      </Grid>
    </Grid>
  );
};

export default SingleHMO;
