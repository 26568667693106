import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

// Custom styled Tabs component
const StyledTabs = styled(Tabs)({
  minHeight: "unset",
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiTabs-flexContainer": {
    gap: "10px",
  }
});

// Custom styled Tab component
const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  minHeight: "unset",
  padding: "8px 48px",
  borderRadius: "12px",
  fontSize: "14px",
  fontWeight: 500,
  color: theme.palette.text.primary,
  backgroundColor: "transparent",
  "&.Mui-selected": {
    color: "#fff",
    backgroundColor: "#3E5EA9",

    "& .tab-count": {
      border: "none",
      backgroundColor: "white",
      color: "#3E5EA9"
    }
  },
  "& .tab-count": {
    borderRadius: '100%',
    border: '1px solid rgba(128, 128, 128, 0.25)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    width: '40px',
    height: '40px'
  }
}));

const SegmentedControl = ({
  tabs,
  onChange,
  defaultValue = "smes",
  value: controlledValue,
}) => {
  const [internalValue, setInternalValue] = useState(defaultValue);

  // Determine if component is controlled or uncontrolled
  const isControlled = controlledValue !== undefined;
  const currentValue = isControlled ? controlledValue : internalValue;

  const handleChange = (event, newValue) => {
    if (!isControlled) {
      setInternalValue(newValue);
    }
    if (onChange) {
      onChange(event, newValue);
    }
  };

  return (
    <Box
    >
      <StyledTabs
        value={currentValue}
        onChange={handleChange}
      >
        {tabs.map((tab, index) => (
          <StyledTab key={tab.value} value={tab.value} label={
            <Box sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center'
            }}>
              <p>{tab.label}</p>
              {tab.count !== undefined && <p className="tab-count">{tab.count}</p>}
            </Box>
          } />
        ))}
      </StyledTabs>
    </Box>
  );
};

SegmentedControl.propTypes = {
  /**
   * Array of tab objects with label and value
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      count: PropTypes.number
    })
  ),

  /**
   * Callback fired when the tab changes
   */
  onChange: PropTypes.func,

  /**
   * Default value for uncontrolled component
   */
  defaultValue: PropTypes.string,

  /**
   * Current value for controlled component
   */
  value: PropTypes.string,
};

export default SegmentedControl;