import { useQuery } from "@apollo/client";
import { Box, Typography, Grid } from "@mui/material";
import CategoryCard from "components/cards/CategoryCard";
import { getMicroInsuranceGraphData, getMicroInsurancePlans, getMicroInsuranceStatsQuery } from "components/graphQL/useQuery";
import AccountsGroupIcon from "components/Icons/AccountsGroupIcon";
import MoneyReceiveIcon from "components/Icons/MoneyReceiveIcon";
import ShieldCrossIcon from "components/Icons/ShieldCrossIcon";
import { CardItem } from "components/layouts";
import MicroInsuranceLineChart from "components/layouts/MicroInsuranceLineChart";
import HorizontalBarChart from "components/Utilities/HorizontalBarChart";
import { CustomSelect } from "components/validation/Select";
import { formatToNaira } from "helpers/func";
import { capitalize } from "lodash";
import React, { useState } from "react";

const MicroInsurance = () => {
  // summary stats data fetching
  const currentMonthIndex = new Date().getMonth() + 1;
  const [statsMonthIndex, setStatsMonthIndex] = useState(currentMonthIndex);
  const { data, loading: statsLoading } = useQuery(getMicroInsuranceStatsQuery, {
    variables: {
      monthIndex: statsMonthIndex
    }
  });
  const statsData = data?.getMicroInsuranceStats;
  const stats = {
    totalRevenue: statsData?.revenue?.totalRevenue || 0,
    topPlan: statsData?.plan?.planName || '',
    totalUsers: statsData?.users?.totalUsers || 0
  };

  // plans and graph data fetching
  const [selectedPlansDuration, setSelectedPlansDuration] = useState("monthly")
  const { data: plansData, loading: plansLoading } = useQuery(getMicroInsurancePlans,
    {
      variables: {
        duration: selectedPlansDuration
      },
      onCompleted: (data) => {
        setSelectedPlanId(data.getExternalPlans?.data?.[0]?._id)
      }
    });
  const plans = plansData?.getExternalPlans?.data;
  const plansSelectOptions = plans?.map(plan => ({
    key: plan.name,
    value: plan._id
  }));
  const [selectedPlanId, setSelectedPlanId] = useState("");

  const { data: graphData, loading: graphLoading } = useQuery(getMicroInsuranceGraphData, {
    variables: {
        planId: selectedPlanId,
        startMonthIndex: 1,
        endMonthIndex: 12,
        year: new Date().getFullYear() // current year
      },
      skip: !selectedPlanId
    });
  const graphMonthlyRevenue = graphData?.getMicroInsurancePlan?.revenue;

  const activeInactiveStats = graphData?.getMicroInsurancePlan?.statistics?.map(item => ({
    label: capitalize(item.status) + ` (${item.count})`,
    value: item.percentage,
    color: barColorByStatus[item.status]
  }));

  return (
    <Box mt={4} pl={{ xs: 0, md: 3 }}>
      <Typography variant="h3">User Types</Typography>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} md={4}>
          <CategoryCard
            title="Business"
            accentColor="#1A7ABF"
            linkTo="/plans/microinsurance/business"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CategoryCard
            title="Family"
            accentColor="#3EA584"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CategoryCard
            title="Individual"
            accentColor="#EEB76B"
          />
        </Grid>
      </Grid>

      {/* Statistics Month Selector */}
      <Box mt={4} width="fit-content">
        <CustomSelect
          value={statsMonthIndex}
          options={getMonthsSelectOptions()}
          onChange={(e) => {
            setStatsMonthIndex(e.target.value)
          }}
        />
      </Box>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={4}>
          <CardItem
            value={{
              name: "Total Revenue",
              id: 0,
              value: formatToNaira(stats.totalRevenue),
              // percentageValue: 4.3,
              icon: <MoneyReceiveIcon width={54} height={54} />,
            }}
            loading={statsLoading}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CardItem
            value={{
              name: "Top Plan",
              id: 1,
              value: stats.topPlan,
              icon: <ShieldCrossIcon width={54} height={54} />,
            }}
            loading={statsLoading}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CardItem
            value={{
              name: "Total Accounts Created",
              id: 2,
              value: stats.totalUsers,
              // percentageValue: 4.3,
              icon: <AccountsGroupIcon width={54} height={54} />,
            }}
            loading={statsLoading}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={3}>
        <Grid item xs={12} lg={8}>
          <Typography variant="h2" component="h2">Revenue</Typography>
          <Box mt={2} bgcolor={"white"} borderRadius={3} border={"1px solid #E6E6E6"}
            px={4}
            pt={3}
            pb={5}
          >
            <Grid container justifyContent={"space-between"} mb={5}>
              <Grid item>
                <CustomSelect
                  value={selectedPlansDuration}
                  options={plansDurationOptions}
                  onChange={(e) => setSelectedPlansDuration(e.target.value)}
                />
              </Grid>

              <Grid item>
                <CustomSelect
                  value={selectedPlanId}
                  options={
                    plansLoading ?
                      [{ key: 'Loading...', value: '' }]
                      : plansSelectOptions
                  }
                  onChange={(e) => setSelectedPlanId(e.target.value)}
                />
              </Grid>
            </Grid>
            <MicroInsuranceLineChart monthlyRevenueData={graphMonthlyRevenue} />
          </Box>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Box width="100%" bgcolor={"white"} borderRadius={4} p={4}
            sx={{
              boxShadow: "0px 2px 6px 0px #0D0A2C14",
            }}
          >
            <Typography fontSize="14px" color="#9291A5" component="h2">Statistics</Typography>
            <Typography component="p" fontSize={"18px"} color="#1E1B39">Plans</Typography>

            <Box mt={3} sx={{ opacity: graphLoading ? 30 : 100 }}>
              <HorizontalBarChart data={(graphLoading || !activeInactiveStats?.length) ?
                emptyActiveInactiveStats
                : activeInactiveStats} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MicroInsurance;

const plansDurationOptions = [
  {
    key: 'Monthly',
    value: 'monthly'
  },
  {
    key: 'Annually',
    value: 'annually',
  },
  {
    key: 'One-off',
    value: 'one-off'
  }
]

const getMonthsSelectOptions = () => {
  const currentMonthIndex = new Date().getMonth();
  let monthOptions = [];

  for (let i = 1; i <= currentMonthIndex + 1; i++) {
    monthOptions.push({
      key: getMonthName(i - 1),
      value: i
    })
  }

  return monthOptions;
}

const getMonthName = (index) => {
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  return months[index] || "Invalid month index";
};

const barColorByStatus = {
  active: "#3EA584",
  inactive: "#BF1A2F"
};

const emptyActiveInactiveStats = [{ label: "Active", value: 0 }, { label: "Inactive", value: 0 }];