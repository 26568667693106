import React from "react";
import { Grid } from "@mui/material";
import logo from "assets/images/heala-logo.png";
import InfoCard from "components/cards/InfoCard";
import hmoLogo from "assets/images/hmo-logo.png";
import hospitalLogo from "assets/images/hospitals.png";
import shieldCrossLogo from "assets/images/shield-cross.svg";

const SubscriptionPlans = () => {
  return (
    <Grid>
      <Grid container spacing={2} flexWrap="wrap">
        <Grid item xs={12} md={6} lg={4}> 
          <InfoCard
            variant="img"
            imgUrl={logo}
            alt="Heala logo"
            hasMenu={false}
            linkTo="plans/heala-plans"
            subTitle=""
            title="Heala"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}> 
          <InfoCard
            variant="img"
            imgUrl={hospitalLogo}
            alt="hospitals logo"
            hasMenu={false}
            linkTo="plans/hospitals"
            subTitle=""
            title="Hospitals"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}> 
          <InfoCard
            variant="img"
            imgUrl={hmoLogo}
            alt="Hmo logo"
            hasMenu={false}
            linkTo="plans/hmo-plans"
            subTitle=""
            title="HMO"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}> 
          <InfoCard
            variant="img"
            imgUrl={shieldCrossLogo}
            alt="Micro insurance logo"
            hasMenu={false}
            linkTo="plans/microinsurance"
            subTitle=""
            title="Micro - insurance plans"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubscriptionPlans;
