import * as React from "react";
const ShieldCrossIcon = (props) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.566 5.652L24 2L40.434 5.652C40.8781 5.75073 41.2753 5.99793 41.56 6.35281C41.8447 6.70769 41.9999 7.14903 42 7.604V27.578C41.9999 29.5535 41.512 31.4984 40.5798 33.24C39.6475 34.9817 38.2997 36.4663 36.656 37.562L24 46L11.344 37.562C9.70055 36.4665 8.35292 34.9822 7.4207 33.2409C6.48847 31.4996 6.00047 29.5551 6 27.58V7.604C6.00008 7.14903 6.15528 6.70769 6.43998 6.35281C6.72468 5.99793 7.12187 5.75073 7.566 5.652ZM22 20H16V24H22V30H26V24H32V20H26V14H22V20Z"
      fill="#3E5EA9"
    />
  </svg>
);
export default ShieldCrossIcon;
