import * as React from "react";
const AccountsGroupIcon = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 7.33398C17.2377 7.33398 18.4247 7.82565 19.2998 8.70082C20.175 9.57599 20.6667 10.763 20.6667 12.0007C20.6667 13.2383 20.175 14.4253 19.2998 15.3005C18.4247 16.1757 17.2377 16.6673 16 16.6673C14.7623 16.6673 13.5753 16.1757 12.7002 15.3005C11.825 14.4253 11.3333 13.2383 11.3333 12.0007C11.3333 10.763 11.825 9.57599 12.7002 8.70082C13.5753 7.82565 14.7623 7.33398 16 7.33398ZM6.66667 10.6673C7.41333 10.6673 8.10667 10.8673 8.70667 11.2273C8.50667 13.134 9.06667 15.0273 10.2133 16.5073C9.54667 17.7873 8.21333 18.6673 6.66667 18.6673C5.6058 18.6673 4.58839 18.2459 3.83824 17.4957C3.08809 16.7456 2.66667 15.7282 2.66667 14.6673C2.66667 13.6065 3.08809 12.589 3.83824 11.8389C4.58839 11.0887 5.6058 10.6673 6.66667 10.6673ZM25.3333 10.6673C26.3942 10.6673 27.4116 11.0887 28.1618 11.8389C28.9119 12.589 29.3333 13.6065 29.3333 14.6673C29.3333 15.7282 28.9119 16.7456 28.1618 17.4957C27.4116 18.2459 26.3942 18.6673 25.3333 18.6673C23.7867 18.6673 22.4533 17.7873 21.7867 16.5073C22.9487 15.0063 23.4882 13.1155 23.2933 11.2273C23.8933 10.8673 24.5867 10.6673 25.3333 10.6673ZM7.33333 24.334C7.33333 21.574 11.2133 19.334 16 19.334C20.7867 19.334 24.6667 21.574 24.6667 24.334V26.6673H7.33333V24.334ZM0 26.6673V24.6673C0 22.814 2.52 21.254 5.93333 20.8007C5.14667 21.7073 4.66667 22.9607 4.66667 24.334V26.6673H0ZM32 26.6673H27.3333V24.334C27.3333 22.9607 26.8533 21.7073 26.0667 20.8007C29.48 21.254 32 22.814 32 24.6673V26.6673Z"
      fill="#EEB76B"
    />
  </svg>
);
export default AccountsGroupIcon;
