import { format } from "date-fns";
import moment from "moment";

export const arrangeItems = (array) => {
  let finalArray = [];
  let n = 0;
  let arrayValues = [];
  let arrayObject = {};
  arrayObject.name = undefined;

  // array.sort();
  array.forEach((element) => {
    let container = element.split(":");
    if (arrayObject.name == container[0]) {
      arrayValues.push(container[1]);
      arrayObject.value = arrayValues;
    } else {
      finalArray[n] = arrayObject;
      arrayValues = [];
      arrayObject = {};
      arrayObject.name = container[0];
      arrayValues.push(container[1]);
      arrayObject.value = arrayValues;
      n += 1;
    }
  });
  finalArray.shift();
  return finalArray;
};

export const removeEmptyStringValues = (obj) => {
  try {
    let newObj = {};
    for (const key in obj) {
      if (obj[key] !== "") {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("error from removeEmptyStringValues FN", err);
    return obj;
  }
};

export const setSideNav = (appNavData, pathname, setNav) => {
  try {
    const pathArr = pathname.split("/");
    if (pathArr.length < 2) {
      setNav(0);
      return;
    }

    appNavData.map((data) => {
      if (data.path === `/${pathArr[1]}`) {
        setNav(data?.id);
      }
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Something sent wrong with setSideNav FN", error);
  }
};

export const dateMoment = (dateString) => {
  const formatedDate = moment(dateString).utc().format("YYYY-MM-DD");
  if (formatedDate === "Invalid date") {
    return null;
  } else {
    return formatedDate;
  }
};

export const getSearchPlaceholder = () => {
  return "Search by first name, last name, email...";
};
export const HealGgetSearchPlaceholder = (filterBy) => {
  return filterBy === "id" ? "Search by UserTypesId " : "";
};

export const getDynamicSearchPlaceholder = (
  filterBy,
  obj = { hmoId: "Search by HMO ID" }
) => {
  let placeHolder = "";
  Object.keys(obj).forEach((key) => {
    if (key === filterBy) placeHolder = obj[key];
  });
  return placeHolder;
};

export const getInitials = (name) => {
  try {
    const splitedNamesArr = name.split(" ");

    const initailsArr = splitedNamesArr.map((name) => {
      const splitedNameArr = name.split("");
      return splitedNameArr[0];
    });

    return initailsArr.join("");
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error from getInitials func.", error);
    return "";
  }
};

export const formatDate = (date, formatType) => {
  try {
    const parsedDate = Date.parse(date);
    const formattedDate = format(parsedDate, formatType);
    return formattedDate;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return "No date";
  }
};

export const getTodaysStartEndDate = () => {
  const now = new Date();
  const todaysDate = format(new Date(), "yyyy-MM-dd");
  const tomorrowsDate = format(now.setDate(now.getDate() + 1), "yyyy-MM-dd");

  return {
    startDate: todaysDate,
    endDate: tomorrowsDate,
  };
};

export const getYesterdaysStartEndDate = () => {
  const now = new Date();
  const yesterdaysDate = format(
    new Date().setDate(now.getDate() - 1),
    "yyyy-MM-dd"
  );
  const todaysDate = format(new Date(), "yyyy-MM-dd");

  return {
    startDate: yesterdaysDate,
    endDate: todaysDate,
  };
};

export const calculateFilterDate = (type) => {
  switch (type) {
    case "today":
      return getTodaysStartEndDate();

    case "yesterday":
      return getYesterdaysStartEndDate();

    default:
      break;
  }
};

export const onhandleSingleSelectClick = (
  id,
  selectedRows,
  setSelectedRows
) => {
  const selectedIndex = selectedRows.indexOf(id);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selectedRows, id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selectedRows.slice(1));
  } else if (selectedIndex === selectedRows.length - 1) {
    newSelected = newSelected.concat(selectedRows.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selectedRows.slice(0, selectedIndex),
      selectedRows.slice(selectedIndex + 1)
    );
  }

  setSelectedRows(newSelected);
};

const numFormat = (numArr = [], length, denum, suffix) => {
  const index = length - denum;
  const dot = [","];
  const firstArr = numArr.slice(0, index);
  const secondArr = numArr.slice(index, numArr.length);
  const arrWithDot = firstArr.concat(dot, secondArr);

  let newArr = [];
  if (arrWithDot[arrWithDot.length - 1] === ",") {
    arrWithDot.pop();
    newArr = arrWithDot;
  } else {
    newArr = arrWithDot.slice(0, index + 4);
  }

  const formatArr = newArr.join("");
  const finalValue = `${formatArr}${suffix}`;
  return finalValue;
};

export const formatNum = (num) => {
  const numString = num.toString();
  const numArr = numString.split("");
  const numLength = numArr.length;

  if (numLength < 4) {
    return numFormat(numArr, numLength, 0, "");
  }
  if (numLength > 3 && numLength < 7) {
    return numFormat(numArr, numLength, 3, "");
  }
  if (numLength > 6 && numLength < 10) {
    return numFormat(numArr, numLength, 6, "M");
  }
  if (numLength > 9 && numLength < 13) {
    return numFormat(numArr, numLength, 9, "B");
  }
  if (numLength > 12 && numLength < 15) {
    return numFormat(numArr, numLength, 12, "T");
  }
};

export const formatSecs = (secs) => {
  try {
    const time = Number(secs);
    const mins = Math.floor(time / 60);
    const seconds = time - mins * 60;
    return `${mins}m ${seconds}s`;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return "0s";
  }
};

export const getVariables = (reason) => ({
  create: { reason },
  resolve: {
    reason,
    disputeStatus: "resolved",
    isDisputed: false,
  },
  cancel: {
    reason,
    disputeStatus: "cancelled",
    isDisputed: false,
  },
  completeConsultation: {},
  cancelConsultation: { reason },
  resendEmail: {},
});

export const isImageUrl = (url) => {
  const regex = /^https?:\/\/.*\/.*\.(png|gif|webp|jpeg|jpg)\??.*$/gim;
  let result;
  if (url.match(regex)) {
    result = {
      match: url.match(regex),
    };
  } else {
    result = false;
  }
  return result;
};

export const isPdfUrl = (url) => {
  const regex = /^https?:\/\/.*\/.*\.(pdf)\??.*$/gim;
  return regex.test(url);
};

export const scrollToRepliedMsg = async (msgId, alignedLeft) => {
  try {
    const contentId = `msg_${msgId}`;
    const el = document.getElementById(contentId);
    const msgCont = document.getElementById("style-1");
    const className = alignedLeft ? "shake-left" : "shake-right";
    if (el) {
      msgCont?.scrollTo({
        top: el.offsetTop,
        behavior: "smooth",
      });

      el.classList.add(className);
      setTimeout(() => {
        el.classList.remove(className);
      }, 2000);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("couldn't scroll to message container", error);
  }
};

export const sortByDate = (values) => {
  try {
    return values.sort((current, prev) => {
      const currentDate = new Date(current?.timestamp?.toDate());
      const prevDate = new Date(prev.timestamp.toDate());
      return currentDate - prevDate;
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return values;
  }
};

export const formulateConsultationVarForReferral = (
  formValues,
  prevConsultation
) => {
  const description =
    !formValues?.description || formValues?.description === ""
      ? "nil"
      : formValues?.description;

  const firstNotice =
    !formValues?.firstNotice || formValues?.firstNotice === ""
      ? "nil"
      : formValues?.firstNotice;

  return {
    consultationOwner: "Heala",
    createdThrough: "doctor-direct",
    patient: prevConsultation?.patient?._id,
    doctor: formValues?.doctor,
    symptoms: (prevConsultation?.symptoms || []).map((symptom) => ({
      name: symptom?.name,
    })),
    discomfortLevel: prevConsultation?.discomfortLevel,
    description,
    firstNotice,
    type: "scheduled",
    status: "pending",
    providerId: prevConsultation?.providerId,
    contactMedium: formValues?.contactMedium,
    paid: true,
    referral: true,
    fee: Number(`${formValues?.fee}`),
    time: `${formValues?.date}T${formValues?.time}:00`,
  };
};

export const formatToNaira = (amount) => {
  // Convert to number in case a string is provided
  const numAmount = Number(amount);

  // Check if it's a valid number
  if (isNaN(numAmount)) {
    return "Invalid amount";
  }

  // Format with Nigerian locale and Naira currency symbol
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(numAmount);
};

export const extractUserIdFromDociId = (dociId) => {
  try {
    if (!dociId || typeof dociId !== "string") return "";

    const parts = dociId.trim().split("-");
    if (parts.length !== 2) return "";

    const userId = parts[1];
    return userId || "";
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error extracting userId from dociId:", error);
    return "";
  }
};

export const parseDate = (dateString, stringFormatter = "dd/MM/yyyy") => {
  const error = "Invalid Date";
  if (dateString === null) return error;
  const date = new Date(dateString);
  if (!isNaN(date.getTime()) === false) return error;
  return format(date, stringFormatter);
};
