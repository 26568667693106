import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Button, Card } from "@mui/material";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const CategoryCard = ({
  title,
  viewMoreText = "View more",
  accentColor = "#0066cc",
  onClick = () => { },
  linkTo
}) => {
  return (
    <Link to={linkTo}>
    <Card
      sx={{
        borderRadius: 2,
        border: "1px solid #EDEDED",
        position: "relative",
        overflow: "hidden",
        px: "26px",
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
        }
      }}
      elevation={0}

    >
      {/* Left accent bar */}
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          width: 10,
          backgroundColor: accentColor,
        }}
      />

      {/* Content */}
      <Box py={2}>
        <Typography
          variant="h2"
          component="h2"
          sx={{
            fontWeight: 500,
            mb: 3,
          }}
        >
          {title}
        </Typography>

        <Button
          variant="text"
          endIcon={<ChevronRightIcon />}
          onClick={onClick}
          sx={{
            color: "#3E5EA9",
            fontSize: "13px",
            textTransform: "none",
            fontWeight: 500,
            p: 0,
            "&:hover": {
              backgroundColor: "transparent",
              textDecoration: "underline",
            }
          }}
        >
          {viewMoreText}
        </Button>
      </Box>


    </Card>
    </Link>
  );
};

CategoryCard.propTypes = {
  /**
   * The title text to display
   */
  title: PropTypes.string,

  /**
   * Text for the "view more" link
   */
  viewMoreText: PropTypes.string,

  /**
   * Color for the accent bar and action text
   * Can be any valid CSS color value
   */
  accentColor: PropTypes.string,

  /**
   * Function called when the "view more" button is clicked
   */
  onClick: PropTypes.func,

  linkTo: PropTypes.string,
};

export default CategoryCard;