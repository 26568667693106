import React, { useState } from 'react';
import {
  Box, Typography, Avatar, Stack, IconButton, Menu, MenuItem, Grid, useMediaQuery,
  useTheme, Drawer
} from "@mui/material";
import { ChevronRight, MoreHoriz, Person } from "@mui/icons-material";
import { capitalize } from "lodash";
import StatusPill from 'components/Utilities/StatusPill';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { getEmployee, getSubscription, getEmployeeDependants } from "components/graphQL/useQuery";
import { Loader } from 'components/Utilities';
import { formatDate } from 'helpers/func';
import { differenceInYears } from 'date-fns';


const EmployeeProfile = () => {
  const [isDependantDrawerOpen, setIsDependantDrawerOpen] = useState(false);
  const [selectedDependant, setSelectedDependant] = useState(null);
  const { id } = useParams();

  const { data: _employeeData, loading: employeeDataLoading } = useQuery(getEmployee, {
    variables: {
      id
    }
  });
  const employeeData = _employeeData?.employees?.data?.[0];

  const { data: _employeeSubscriptionData, loading: employeeSubscriptionDataLoading } = useQuery(getSubscription, {
    variables: {
      email: employeeData?.email
    },
    skip: !employeeData
  });
  const employeeSubscriptionData = _employeeSubscriptionData?.getSubscription;

  const { data: _dependantsData, loading: dependantsLoading } = useQuery(getEmployeeDependants, {
    variables: {
      principal: id
    },
    skip: !id
  });
  const dependants = _dependantsData?.employees?.data || [];

  if (employeeDataLoading || employeeSubscriptionDataLoading) return <Loader />;

  return (
    <Box mt={3}>
      <EmployeeDetails employee={{
        ...employeeData,
        subscription: employeeSubscriptionData
      }} />

      {dependantsLoading ? (
        <Box mt={2} display="flex" justifyContent="center">
          <Loader />
        </Box>
      ) : dependants.length === 0 ? (
        null
      ) : (
        <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#F1F5FC",
                  borderRadius: "8px",
                  padding: "16px",
                  mt: 3
                }}
              >
                <Typography sx={{ color: "#3E5EA9", fontSize: "14px", fontWeight: "normal" }}>Dependants</Typography>
                <ChevronRight sx={{ fontSize: "24px", color: "#3E5EA9" }} />
              </Box>

              <Grid container spacing={4} mt={1}>
                {dependants.map((dependant) => (
                  <Grid key={dependant._id} item xs={12} sm={6}>
                    <ProfileCard
                      dependant={dependant}
                      onViewProfile={() => {
                        setSelectedDependant(dependant);
                        setIsDependantDrawerOpen(true);
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
      )}

      <DependantDetailsDrawer
        open={isDependantDrawerOpen}
        onClose={() => {
          setIsDependantDrawerOpen(false);
          setSelectedDependant(null);
        }}
        dependant={selectedDependant}
        subscriptionName={employeeSubscriptionData?.planId?.name}
      />
    </Box>
  );
};

export default EmployeeProfile;


const EmployeeDetails = ({ employee }) => {
  return (
    <Box sx={{ border: "1px solid rgba(128, 128, 128, 0.25)", borderRadius: 2, bgcolor: "white" }}>
      {/* Top Section */}
      <Stack direction="row" alignItems="end" justifyContent="space-between" padding="32px 48px">
        <Stack direction="row" spacing={3} alignItems="center">
          <Avatar src={employee?.profileId?.image} sx={{ width: 100, height: 100 }}>
            <Person />
          </Avatar>
          <Stack spacing={1.5}>
            <Typography fontWeight={500}>{employee?.firstName + " " + employee?.lastName}</Typography>
            <Typography variant="body2">
              Employee No: <strong>{employee?.profileId?.hmoId}</strong>
            </Typography>
            <Typography variant="body2" sx={{ color: "#3E5EA9" }}>
              {employee?.subscription?.planId?.name}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body2">Status :</Typography>
          <StatusPill label={capitalize(employee?.status)} type={{
            "accepted": "success",
            "pending": "warning",
          }[employee?.status]} />
        </Stack>
      </Stack>

      {/* Divider */}
      <Box sx={{ borderBottom: "1px solid rgba(128, 128, 128, 0.15)" }} />

      {/* Details Section */}
      <Stack spacing={3} padding="48px">

        <InfoRow
          label="Enrollment Date"
          value={formatDate(employee.createdAt, "PPP")}
        />
        <InfoRow label="Expiry Date" value={formatDate(employee.subscription?.subscriptionExpiresAt, "PPP")} />
        <InfoRow label="Email address" value={employee.email} />
        <InfoRow label="Phone number" value={employee.phoneNumber} />

        <InfoRow
          label="Consultations"
          // todo: ask BE to return this
          value={'N/A'}
        />
      </Stack>
    </Box>
  );
};

// Helper component for displaying label-value pairs
const InfoRow = ({ label, value }) => (
  <Stack direction="row" justifyContent="space-between">
    <Typography variant="body2" color="textSecondary">
      {label} :
    </Typography>
    <Typography variant="body2">{value}</Typography>
  </Stack>
);

const ProfileCard = ({ dependant, onViewProfile }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        border: "1px solid #E0E0E0",
        borderRadius: 2,
        padding: 3,
        bgcolor: "white",
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography sx={{ fontSize: 13, color: "rgba(93, 98, 108, 1)" }}>
          Date invited: {formatDate(dependant.createdAt, "dd/MM/yyyy")}
        </Typography>
        <Box>
          <IconButton onClick={handleClick}>
            <MoreHoriz />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={() => {
              onViewProfile();
              handleClose();
            }}>View profile</MenuItem>
          </Menu>
        </Box>
      </Stack>

      <Stack spacing={2} mt={2}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 1,
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>
            {`${dependant.firstName} ${dependant.lastName}`}
          </Typography>
          <Typography sx={{ fontSize: 14, color: "#777" }}>
            {dependant.dob ? differenceInYears(new Date(), new Date(dependant.dob)) + ' yrs' : 'N/A'}
          </Typography>
        </Box>

        <Typography sx={{ fontSize: 14, color: "#777" }}>
          {dependant.profileId?.hmoId}
        </Typography>
        <Typography sx={{ fontSize: 14, color: "#777" }}>
          {capitalize(dependant.relationship || 'N/A')}
        </Typography>
      </Stack>
    </Box>
  );
};

const DependantDetailsDrawer = ({ open, onClose, dependant, subscriptionName }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (!dependant) return null;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          width: isMobile ? "100%" : 570,
          padding: 3,
          boxSizing: "border-box",
        },
      }}
    >
      <Stack direction="row" alignItems="center">
        <IconButton onClick={onClose} sx={{ color: "common.blue" }}>
          <ArrowBackIcon />
        </IconButton>

        <Typography
          variant="h4"
          mx="auto"
        >
          Dependant details
        </Typography>
      </Stack>

      <Stack spacing={2} alignItems="center" mt={6}>
        <Box display="flex" mt={7}>
          <Avatar src={dependant.profileId?.image} sx={{ width: 64, height: 64 }}>
            <Person />
          </Avatar>
        </Box>

        <Typography variant="body2" fontWeight="bold">
          {`${dependant.firstName} ${dependant.lastName}`}
        </Typography>
        <Typography variant="body2">
          Employee No: <b>{dependant.profileId?.hmoId}</b>
        </Typography>
        <Typography variant="body2" sx={{ color: "common.blue" }}>
          {subscriptionName}
        </Typography>
      </Stack>

      <Box mt={5}>
        <Stack spacing={2}>
          {[
            { label: "Email Address", value: dependant.email },
            { label: "Phone Number", value: dependant.phoneNumber },
            { label: "Date of Birth", value: dependant.dob ? formatDate(dependant.dob, "PP") : 'N/A' },
            { label: "Gender", value: capitalize(dependant.profileId?.gender || 'N/A') },
            { label: "Relationship to principal", value: capitalize(dependant.relationship || 'N/A') },
            // todo: get this
            { label: "No of Consultations", value: dependant.profileId?.consultations || 'N/A' },
            // todo: get this
            { label: "Preferred Hospital", value: dependant.profileId?.provider || 'N/A' }
          ].map((item, index) => (
            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent={{ xs: 'start', md: 'space-between' }} spacing={0.5} key={index}>
              <Typography variant="body2" sx={{ color: "common.blue" }}>
                {item.label}:
              </Typography>
              <Typography variant="body1" sx={{ color: "#5D626C" }}>
                {item.value}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Box>
    </Drawer>
  );
};
