import React, { useMemo, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import SegmentedControl from "./SegmentedControl";
import { CustomButton, Loader, Search } from "components/Utilities";
import { Link as ReactRouterLink } from "react-router-dom";

import { Typography, Avatar, IconButton, Menu, MenuItem, Box, Grid, Pagination, Link } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Business } from "@mui/icons-material";
import AddIcon from "components/Icons/AddIcon";
import { getBusinesses } from "components/graphQL/useQuery";
import { useQuery } from "@apollo/client";
import { NoData } from "components/layouts";

const MicroInsuranceBusinesses = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const currentTab = searchParams.get("type") || "sme";

  const tabs = useMemo(() => ([
    { label: "SME", value: "sme" },
    { label: "Enterprise", value: "enterprise" }
  ]), []);

  const [page, setPage] = useState(1);
  const { data, loading, error, refetch } = useQuery(getBusinesses, {
    variables: {
      type: currentTab,
      page
    },
    notifyOnNetworkStatusChange: true
  });
  const businesses = data?.businesses?.data;

  // Refetch when location changes (handles browser back/forward and direct navigation)
  useEffect(() => {
    refetch({
      type: currentTab,
      page
    });
  }, [location.search, refetch, currentTab, page]);

  const [searchString, setSearchString] = useState("");
  const handleSearch = () => {
    refetch({ search: searchString })
  };

  const handleTabChange = (_, value) => {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set("type", value);
    history.push({
      pathname: location.pathname,
      search: newSearchParams.toString()
    });
    // Reset page when changing tabs
    setPage(1);
  };



  return (
    <Box>
      {/* Tabs */}
      <Box
        sx={{
          mt: 1,
          backgroundColor: "white",
          padding: "16px 32px",
          borderRadius: "24px",
          border: "1px solid rgba(128, 128, 128, 0.25)"
        }}
      >
        <SegmentedControl
          tabs={tabs}
          value={currentTab}
          onChange={handleTabChange}
        />
      </Box>

      <Grid container mt={4} spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item width={364}>
          <Search
            placeholder="Search..."
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            onClickSearchBtn={handleSearch}
          />
        </Grid>

        {currentTab === "enterprise" && (
          <Grid item>
            <CustomButton
              title="Create Enterprise Account"
              startIcon={<AddIcon />}
              type={{
                background: "primary"
              }}
              path="/plans/microinsurance/create-enterprise"
            />
          </Grid>
        )}
      </Grid>


      {/* Companies Grid */}
      {loading ?
        <Loader /> :
        businesses?.length ?
          <Grid container spacing={3} mt={3}>
            {businesses.map((company, i) => (
              <Grid item key={i} xs={12} sm={6} md={4}>
                <CompanyCard
                  id={company._id}
                  name={company.name}
                  employees={company.noOfEmployees}
                  logo={company.image}
                  menuItems={[
                    {
                      text: "Edit",
                      onClick: () => console.log("Edit clicked")
                    },
                    {
                      text: "Delete",
                      onClick: () => console.log("Delete clicked")
                    }
                  ]}
                />
              </Grid>
            ))}
          </Grid>
          :
          <Box my={5}>
            <NoData error={error} />
          </Box>
      }

      <Pagination
        count={data?.businesses.pageInfo.totalPages}
        page={page}
        onChange={(_, value) => setPage(value)}
        color="primary"
        size="large"
        sx={{
          mt: 10,
          mb: 2,

          "& .MuiPagination-ul": {
            gap: { xs: "4px", sm: "8px" },
            justifyContent: "center",
          }
        }}
      />
    </Box>
  );
};

export default MicroInsuranceBusinesses;

const CompanyCard = ({ id, name, employees, logo, menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{
      bgcolor: "white",
      borderRadius: "16px",
      padding: "28px 16px",
      border: "1px solid rgba(128, 128, 128, 0.25)",
      display: "flex",
      alignItems: "center",
    }}
    >
      <Link component={ReactRouterLink} to={`/plans/microinsurance/business/${id}`} sx={{
        display: "flex",
        alignItems: "center"
      }}>
        {/* Company Logo */}
        <Avatar src={logo} alt={name} sx={{
          width: 80, height: 80, marginRight: 3, border: "1px solid #CED0D3",
          backgroundColor: "#CED0D3"
        }}>
          <Business />
        </Avatar>

        {/* Company Info */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h3">
            {name}
          </Typography>
          <Typography color="#5D626C" fontWeight={400}>
            {employees} Employees
          </Typography>
        </Box>
      </Link>

      {/* Three-Dot Menu */}
      <IconButton
        onClick={handleMenuOpen}
        sx={{ marginLeft: "auto", color: "common.blue" }}
        width={3}
        height={3}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              item.onClick();
              handleMenuClose();
            }}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
