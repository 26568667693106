import { useMutation, useQuery } from "@apollo/client";
import { Grid, Paper, Box, Typography, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles"
import { makeStyles } from "@mui/styles";
import { getExternalPlansQuery } from "components/graphQL/useQuery";
import FormikControl from "components/validation/FormikControl";
import { Form, Formik } from "formik";
import { createEnterpriseInitialValues, industriesOptions } from "helpers/mockData";
import { createEnterpriseValidationSchema } from "helpers/validationSchemas";
import React, { useMemo, useState } from "react";
import { CustomButton, Modals } from "components/Utilities";
import { createEnterpriseMutation } from "components/graphQL/Mutation";
import { useSnackbar } from "notistack";
import { showErrorMsg } from "helpers/filterHelperFunctions";
import { Link as ReactRouterLInk } from "react-router-dom";
import DoubleCheckIcon from "components/Icons/DoubleCheckIcon";


const CreateEnterprise = () => {
  const { data: plansData } = useQuery(getExternalPlansQuery);
  const plansOptions = useMemo(() => {
    const plans = plansData?.getExternalPlans?.data || [];
    return plans.map(p => ({
      key: `${p.name} (${p.duration}) - N${p.amount}`,
      value: p._id
    }))
  }, [plansData]);

  const theme = useTheme();
  const buttonType = {
    background: theme.palette.common.black,
    hover: theme.palette.primary.main,
    active: theme.palette.primary.dark,
  };
  const { enqueueSnackbar } = useSnackbar();


  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const [createEnterprise, { loading: isSubmitting }] = useMutation(createEnterpriseMutation)

  const onSubmit = async (values, { resetForm }) => {
    await createEnterprise({
      variables: {
        dateFounded: values.dateFounded,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        image: values.image,
        phoneNumber: values.phone,
        name: values.companyName,
        businessType: "enterprise",
        address: values.address,
        industry: values.industry,
        noOfEmployees: values.noOfEmployees,
        planId: values.preferredPlanId,
      },
      onCompleted: () => {
        resetForm();
        setShowDetailsModal(false);
        setShowSuccessModal(true);
      },
      onError: (error) => {
        showErrorMsg(
          enqueueSnackbar,
          "Could not create account - " + error.message
        );
      }
    });
  }


  return (
    <>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
      />
      <Box
        sx={{
          background: "white",
          padding: { xs: 3, md: 6 }
        }}
      >
        <Typography
          variant="h1"
        >Let&apos;s get you started</Typography>
        <Typography variant="body1" mt={1} sx={{
          color: "common.neutral300",
          fontWeight: 400
        }}>
          Enter the details below
        </Typography>


        <Formik
          onSubmit={onSubmit}
          validationSchema={createEnterpriseValidationSchema}
          initialValues={createEnterpriseInitialValues}
          validateOnChange={true}
        >
          {({ dirty, isValid, setFieldValue, setValues, values, errors, submitForm }) => {
            return (
              <Form>
                <Grid container mt={8}>
                  {/* Left - Company Logo */}
                  <Grid item container direct="column" gap={3} sm={3}>
                    <FormikControl
                      control="file"
                      // !fix: every other 'name' value asides 'image' throws error during image upload
                      name="image"
                      label="Company Logo"
                      setFieldValue={setFieldValue}
                    />
                  </Grid>

                  {/* Right - Other input fields */}
                  <Grid item container direction="column" gap={2.5} md={7}>
                    <Grid item>
                      <FormikControl
                        control="date"
                        name="dateFounded"
                        label="Date Founded"
                        setFieldValue={setFieldValue}
                        setValues={setValues}
                      />
                    </Grid>

                    <Grid item container spacing={2}>
                      <Grid item container sm={6}>
                        <FormikControl
                          control="input"
                          label="First Name"
                          id="firstName"
                          name="firstName"
                          placeholder="Enter first name"
                        />
                      </Grid>
                      <Grid item container sm={6}>
                        <FormikControl
                          control="input"
                          label="Last Name"
                          id="lastName"
                          name="lastName"
                          placeholder="Enter last name"
                        />
                      </Grid>
                    </Grid>

                    <Grid item>
                      <FormikControl
                        control="input"
                        label="Email Address"
                        id="email"
                        name="email"
                        placeholder="Enter email"
                      />
                    </Grid>

                    <Grid item>
                      <FormikControl
                        control="input"
                        label="Phone Number"
                        id="phone"
                        name="phone"
                        placeholder="Enter Phone number"
                      />
                    </Grid>

                    <Grid item>
                      <FormikControl
                        control="input"
                        label="Company name"
                        id="companyName"
                        name="companyName"
                        placeholder="Enter Company Name"
                      />
                    </Grid>

                    <Grid item>
                      <FormikControl
                        control="input"
                        label="Company Address"
                        id="address"
                        name="address"
                        placeholder="Enter Company Address"
                      />
                    </Grid>

                    <Grid item>
                      <FormikControl
                        control="select"
                        options={industriesOptions}
                        name="industry"
                        label="Industry"
                        placeholder="Select Industry"
                      />
                    </Grid>

                    <Grid item>
                      <FormikControl
                        control="input"
                        type="number"
                        label="Number of Employees"
                        name="noOfEmployees"
                        placeholder="Enter number of employees"
                      />
                    </Grid>

                    <Grid item>
                      <FormikControl
                        control="select"
                        options={plansOptions}
                        name="preferredPlanId"
                        label="Preferred plan"
                        placeholder="Select"
                      />
                    </Grid>

                    <Grid item>
                      <CustomButton
                        title="Review and Submit"
                        width="100%"
                        type={buttonType}
                        disabled={!dirty || !isValid}
                        role="button"
                        onClick={() => setShowDetailsModal(true)}
                      />
                    </Grid>

                    <ConfirmDetailsModal
                      isOpen={showDetailsModal}
                      setShowDetailsModal={setShowDetailsModal}
                      formValues={values}
                      onSubmit={submitForm}
                      isSubmitting={isSubmitting}
                      onClose={() => { setShowDetailsModal(false) }}
                      preferredPlanText={plansOptions.find(p => p.value)?.key}
                    />
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </>
  );
};

export default CreateEnterprise;

const ConfirmDetailsModal = ({ onSubmit, isOpen, onClose, formValues, preferredPlanText, isSubmitting }) => {
  const { firstName, lastName, email, phone, companyName, industry, noOfEmployees, address } = formValues
  const industryName = industriesOptions.find(i => i.value === industry)?.key

  const theme = useTheme();
  const buttonType = {
    background: theme.palette.common.black,
    hover: theme.palette.primary.main,
    active: theme.palette.primary.dark,
  };

  return (
    <Modals
      isOpen={isOpen}
      title="Account Details"
      width={{ xs: "90vw", sm: "70vw", lg: "580px" }}
      handleClose={onClose}
    >
      <Box paddingTop={6}>
        <Box component="img" src={formValues.image} width={100} height={100} borderRadius={999} border="1px solid rgba(206, 208, 211, 1)" />
        <Grid container direction="column" spacing={2} mt={3}>
          <ListItem title="Name" value={`${firstName} ${lastName}`} />
          <ListItem title="Email address" value={email} />
          <ListItem title="Phone number" value={phone} />
          <ListItem title="Company name" value={companyName} />
          <ListItem title="Company address" value={address} />
          <ListItem title="Industry" value={industryName} />
          <ListItem title="Number of employees" value={noOfEmployees} />
          <ListItem title="Preferred plan" value={preferredPlanText} />
        </Grid>

        <Box mt={6}>
          <CustomButton
            title="Create account"
            width="100%"
            type={buttonType}
            isSubmitting={isSubmitting}
            role="button"
            onClick={onSubmit}
          />
        </Box>

      </Box>
    </Modals>
  )
}

const ListItem = ({ title, value }) => {

  return (
    <Grid item container justifyContent="space-between">
      <Grid color="rgba(93,98,108,1)" item>{title}</Grid>
      <Grid item color="rgba(12,19,34,1)">{value}</Grid>
    </Grid>
  )
}

const SuccessModal = ({ isOpen, onClose }) => {
  const theme = useTheme();

  return (
    <Modals
      isOpen={isOpen}
      width={{ xs: "90vw", sm: "70vw", lg: "509px" }}
      handleClose={onClose}
    >
      <Box textAlign="center" paddingTop={2}>
        <Box width="fit-content" mx="auto">
          <DoubleCheckIcon />
        </Box>
        <Typography variant="h2" marginTop={2}>
          Account created successfully
        </Typography>
        <Link pt={2} component={ReactRouterLInk} to="/plans/microinsurance/business?type=enterprise" variant="body2" marginTop={2} display="block"
          color={theme.palette.common.blue}
          onClick={onClose}
        >
          Back to Dashboard
        </Link>
      </Box>
    </Modals>
  );
}