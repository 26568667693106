import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Stack } from '@mui/material';

const HorizontalBarChart = ({ data }) => {
  return (
    <Box sx={{ width: '100%', maxWidth: 400 }}>
      {data.map((item, index) => (
        <Stack
          key={index}
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{ mb: 2 }}
        >
          {/* Label */}
          <Typography
            sx={{
              width: 60,
              color: '#615E83',
              fontSize: 14
            }}
          >
            {item.label}
          </Typography>

          {/* Bar container */}
          <Box
            sx={{
              flex: 1,
              position: 'relative',
              height: 8,
              bgcolor: '#f0f0f0',
              borderRadius: 4
            }}
          >
            {/* Colored bar */}
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                width: `${item.value}%`,
                bgcolor: item.color,
                borderRadius: 4
              }}
            />
          </Box>

          {/* Percentage */}
          <Typography
            sx={{
              width: 40,
              fontSize: 12,
              color: '#615E83',
              textAlign: 'right'
            }}
          >
            {item.value}%
          </Typography>
        </Stack>
      ))}

      {/* X-axis labels */}
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ pl: 10, pr: 5, mt: 1 }}
      >
        <Typography sx={{ fontSize: 12, color: '#615E83' }}>0%</Typography>
        <Typography sx={{ fontSize: 12, color: '#615E83' }}>50%</Typography>
        <Typography sx={{ fontSize: 12, color: '#615E83' }}>100%</Typography>
      </Stack>
    </Box>
  );
};

HorizontalBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired
    })
  ).isRequired
};

export default HorizontalBarChart;