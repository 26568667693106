import React from "react";

const DoubleCheckIcon = () => {
  return (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.1667 41.6166L41.31 55.7599L69.59 27.4766M10.5 41.6166L24.6433 55.7599M52.9267 27.4766L42.1667 38.3332" stroke="#3EA584" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default DoubleCheckIcon;