import React, { useEffect, useState } from "react";
import { Grid, Pagination } from "@mui/material";
import useAlert from "hooks/useAlert";
import { NoData } from "components/layouts";
import { Loader } from "components/Utilities";
import { addHMOInitialValues } from "helpers/mockData";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getProviders } from "components/graphQL/useQuery";
import { deletProvider } from "components/graphQL/Mutation";
import DeletePartner from "components/modals/DeleteOrDisable";
import AddEditHMOModal from "components/modals/AddEditHMOModal";
import InfoCard, { AddHmoCard } from "components/cards/InfoCard";

const Hmo = () => {
  const { displayAlert, getErrorMsg } = useAlert();
  const [hmos, setHmos] = useState([]);
  const [deleteHMO] = useMutation(deletProvider);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editHMOData, setEditHMOData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [hmoIdToDelete, setHmoIdToDelete] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;
  const [fetchAllHMO, { error, loading, refetch }] = useLazyQuery(
    getProviders,
    {
      variables: { userTypeId: "61ed2b68e6091400135e3dba", page: page },
    }
  );

  const refetchData = () => {
    refetch()
      .then(({ data }) => {
        setHmos(data?.getProviders?.provider);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    fetchAllHMO()
      .then(({ data }) => {
        setHmos(data?.getProviders?.provider);

        const total = Math.ceil(
          data?.getProviders?.pageInfo?.totalDocs / itemsPerPage
        );
        setTotalPages(total);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [fetchAllHMO, page]);

  if (loading) return <Loader />;
  if (error) return <NoData error={error} />;
  return (
    <Grid container gap={4}>
      <Grid
        item
        container
        gap={2}
        spacing={2}
        sx={{ justifyContent: "flex-end", pt: 3 }}
      >
        <button
          style={{
            cursor: "pointer",
            border: "none",
            background: "transparent",
          }}
          onClick={() => setAddModal(true)}
        >
          <AddHmoCard />
        </button>
      </Grid>
      <Grid
        item
        container
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1,1fr)",
            sm: "repeat(2,1fr)",
            lg: "repeat(3,1fr)",
          },
          gap: "1.5rem",
        }}
      >
        {hmos.map((hmo, idx) => {
          const {
            _id,
            name,
            icon,
            address,
            phone,
            email,
            iconAlt,
            userTypeId,
            enrolleeCount,
            rareCase,
          } = hmo;
          const count =
            (enrolleeCount || 0) < 2
              ? `${enrolleeCount} member`
              : `${enrolleeCount} members`;

          const editInitialValues = {
            id: _id,
            name: name,
            icon: icon,
            address: address || "",
            phone: phone || "",
            email: email || "",
            iconAlt: iconAlt || "",
            rareCase: rareCase || false,
            userTypeId: userTypeId || "61ed2b68e6091400135e3dba",
          };
          return (
            <Grid key={idx} item flex={1}>
              <InfoCard
                imgUrl={hmo?.icon}
                imgAlt={hmo?.name}
                linkTo={`hmo/${hmo?._id}`}
                subTitle={`${count}` || "N/A members"}
                title={hmo?.name}
                onClickEdit={() => {
                  setEditHMOData(editInitialValues);
                  setEditModal(true);
                }}
                onClickDelete={
                  () => null /* {
                  setHmoIdToDelete(_id);
                  setDeleteModal(true);
                } */
                }
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        sx={{
          mt: 4,
          mb: 2,
          "& .MuiPagination-ul": {
            justifyContent: "center",
          },
        }}
      >
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
          size="large"
          sx={{
            "& .MuiPagination-ul": {
              gap: { xs: "4px", sm: "8px" },
            },
          }}
        />
      </Grid>
      <Grid>
        {/* CONFIRM DELETE MODAL */}
        <DeletePartner
          open={deleteModal}
          setOpen={setDeleteModal}
          title="Delete HMO"
          btnValue="delete"
          onConfirm={async () => {
            setDeleteModal(false);
            deleteHMO({
              variables: {
                id: hmoIdToDelete,
              },
            })
              .then(({ data }) => {
                if (data) {
                  displayAlert("success", "HMO deleted successfully");
                  refetchData();
                }
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error);
                const errMsg = getErrorMsg(error);
                displayAlert("error", errMsg);
              });
          }}
          confirmationMsg="delete HMO"
          onCancel={() => {
            setHmoIdToDelete(null);
          }}
        />

        {/* ADD MODAL */}
        <AddEditHMOModal
          isOpen={addModal}
          setIsOpen={setAddModal}
          type="add"
          initialValues={addHMOInitialValues}
          onSuccess={() => {
            setAddModal(false);
            refetchData();
          }}
        />

        {/* EDIT MODAL */}
        <AddEditHMOModal
          isOpen={editModal}
          setIsOpen={setEditModal}
          type="edit"
          initialValues={{
            ...editHMOData,
            userTypeId: editHMOData?.userTypeId?._id,
          }}
          onSuccess={() => {
            setEditModal(false);
            refetchData();
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Hmo;
