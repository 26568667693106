import React, { useState } from "react";
import Props from "prop-types";
import { useQuery } from "@apollo/client";
import { Button, Grid, TableCell, TableRow } from "@mui/material";
import { GET_ACTIVITY_LOGS } from "components/graphQL/useQuery";
import { EmptyTable, EnhancedTable } from "components/layouts";
import TableLayout from "components/layouts/TableLayout";
import { Loader } from "components/Utilities";
import StatusPill from "components/Utilities/StatusPill";
import { activityLogsHeader } from "components/Utilities/tableHeaders";
import MainModal from "components/modals/MainModal";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Copy from "components/Copy";
import { parseDate } from "helpers/func";

const ActivityLogs = () => {
  const [tab, setTab] = useState("arguments");
  const [openModal, setOpenModal] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [meta, setMeta] = useState({ limit: 20, skip: 1 });

  const { data, loading } = useQuery(GET_ACTIVITY_LOGS, {
    variables: {
      limit: meta.limit,
      page: meta.skip - 1,
    },
  });

  //   const sampleCode = {
  //     arguments: {
  //       data: {
  //         user: "67c9a84c36bbcfe2d4722478",
  //         token: "***REDACTED***",
  //         deviceId: "fcd5babd24d086a5",
  //         role: "doctor",
  //       },
  //     },
  //   };

  const activityLogs = data?.activityLogs || [];

  return (
    <Grid>
      <Grid item flex={1} container direction="column" rowGap={2}>
        <TableLayout
          actions={
            <ActivityLogPagination
              loading={loading}
              meta={meta}
              setMeta={setMeta}
            />
          }
        >
          {loading ? (
            <Loader />
          ) : activityLogs.length > 0 ? (
            /* ================= ACTIVITY LOGS TABLE ================= */
            <Grid
              container
              item
              direction="column"
              overflow="hidden"
              maxWidth={{ md: "100%", sm: "100%", xs: "100%" }}
            >
              <EnhancedTable
                headCells={activityLogsHeader}
                rows={activityLogs}
                paginationLabel="subscription per page"
                hasCheckbox={false}
                hasPagination={false}
              >
                {activityLogs.map((log, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedLog(log);
                        setOpenModal(true);
                      }}
                    >
                      <TableCell
                        scope="row"
                        align="left"
                        className=" !break-words"
                      >
                        {log?.email}
                      </TableCell>
                      <TableCell align="left">
                        {parseDate(log?.timestamp, "dd/MM/yyy hh:mmaa")}
                      </TableCell>

                      <TableCell align="left">
                        <StatusPill type="normal" label={log?.operation} />
                      </TableCell>
                      <TableCell align="left">{log?.endpoint}</TableCell>

                      {/* <TableCell
                        align="left"
                        className="!break-all !max-w-[150px]"
                      >
                        {log?.userAgent}
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              </EnhancedTable>
              <ActivityLogPagination
                loading={loading}
                meta={meta}
                setMeta={setMeta}
              />
            </Grid>
          ) : (
            <EmptyTable
              headCells={activityLogsHeader}
              paginationLabel="Activity Logs per page"
            />
          )}
        </TableLayout>
      </Grid>
      <MainModal
        isOpen={openModal}
        setIsOpen={setOpenModal}
        headerText=""
        width={{ md: "70vw", sm: "90vw", lg: "70vw", xs: "90vw", sp: "526px" }}
      >
        <div>
          {/* HEADER */}
          <div>
            <HeaderItem title="ID" value={selectedLog?._id} canCopy />
            <HeaderItem
              title="EMAIL"
              value={selectedLog?.email}
              canCopy={false}
            />
            <HeaderItem
              title="operation"
              value={selectedLog?.operation}
              canCopy={false}
            />
            <HeaderItem
              title="endpoint"
              value={selectedLog?.endpoint}
              canCopy={false}
            />
            <HeaderItem
              title="timestamp"
              value={parseDate(selectedLog?.timestamp, "dd/MM/yyy hh:mmaa")}
              canCopy={false}
            />
          </div>

          {/* CODE SECTION */}
          <div className="space-x-4 mb-3 mt-10">
            <Button
              onClick={() => setTab("arguments")}
              className="!bg-[#3e5ea9] !text-white"
            >
              Arguments
            </Button>
            <Button
              onClick={() => setTab("result")}
              className="!bg-[#3e5ea9] !text-white"
            >
              Result
            </Button>
            <Button
              onClick={() => setTab("user-agent")}
              className="!bg-[#3e5ea9] !text-white"
            >
              User agent
            </Button>
          </div>
          <div className="max-h-[500px] overflow-y-auto">
            {tab === "arguments" && (
              <SyntaxHighlighter
                language="javascript"
                style={docco}
                showLineNumbers
                wrapLines
                wrapLongLines
              >
                {JSON.stringify(selectedLog?.arguments, null, 2)}
              </SyntaxHighlighter>
            )}
            {tab === "result" && (
              <SyntaxHighlighter
                language="javascript"
                style={docco}
                showLineNumbers
                wrapLines
                wrapLongLines
                // customStyle={{ height: "400px",  }}
              >
                {JSON.stringify(selectedLog?.result, null, 2)}
              </SyntaxHighlighter>
            )}
            {tab === "user-agent" && (
              <SyntaxHighlighter
                language="javascript"
                style={docco}
                wrapLines
                wrapLongLines
              >
                {JSON.stringify(selectedLog?.userAgent, null, 2)}
              </SyntaxHighlighter>
            )}
          </div>
        </div>
      </MainModal>
    </Grid>
  );
};

export default ActivityLogs;

const ActivityLogPagination = ({ meta, setMeta, loading }) => {
  const normalizeNum = (num) => (parseInt(num) < 1 ? 1 : num);

  const handleSetMeta = (name, value) => {
    setMeta((prev) => ({ ...prev, [name]: normalizeNum(value) }));
  };

  return (
    <div className="flex items-end space-x-7">
      <label htmlFor="limit">
        <span className="block text-[14px]">Limit</span>
        <input
          name="limit"
          type="number"
          value={meta?.limit}
          disabled={loading}
          className="max-w-[80px] text-[14px] border py-2 px-4 rounded-lg"
          onChange={(e) => handleSetMeta("limit", e?.target?.value)}
        />
      </label>
      <label htmlFor="skip" className="block">
        <span className="block text-[14px]">Page</span>
        <input
          name="skip"
          type="number"
          value={meta.skip}
          disabled={loading}
          className="max-w-[80px] text-[14px] border py-2 px-4 rounded-lg"
          onChange={(e) => handleSetMeta("skip", e?.target?.value)}
        />
      </label>
      {/* <Button
                disabled={loading}
                className="!bg-[#3e5ea9] !text-white !h-[35px]"
              >
                Filter
              </Button> */}
    </div>
  );
};

ActivityLogPagination.propTypes = {
  meta: Props.object.isRequired,
  setMeta: Props.func.isRequired,
  loading: Props.bool.isRequired,
};

const HeaderItem = ({ title, value, canCopy }) => {
  return (
    <div className="flex items-center space-x-2">
      <p className="font-semibold text-[14px]">{`${title}`.toUpperCase()}:</p>
      <p className=" flex items-center space-x-2">
        <span>{value}</span> {canCopy && <Copy text={value} name={title} />}
      </p>
    </div>
  );
};

HeaderItem.propTypes = {
  title: Props.string.isRequired,
  value: Props.string.isRequired,
  canCopy: Props.bool.isRequired,
};
