import React, { useMemo } from "react";

import { Grid } from "@mui/material";
import { Line } from "react-chartjs-2";



const MicroInsuranceLineChart = ({ monthlyRevenueData }) => {
  const monthlyRevenueGraphData = useMemo(() => {
    const graphData = Array(12).fill(0);

    (monthlyRevenueData || []).forEach((monthRevenue) => {
      graphData[(monthRevenue.month - 1)] = monthRevenue.totalRevenue;
    })

    return getFullGraphData(graphData);

  }, [monthlyRevenueData]);

  return (
    <Grid item container>
      <Line data={monthlyRevenueGraphData} options={graphOptions} />
    </Grid>
  )
}

const graphOptions = {
  "responsive": true,
  "maintainAspectRatio": true,
  "backgroundColor": "#f3f3f3",
  "locale": "en",
  "scales": {
    "y": {
      ticks: {
        callback: function (value) {
          return (value / 1000).toFixed(1) + "k";
        }
      },
      "min": 0,
      "grid": {
        "color": "rgba(0,0,0,0.05)",
        "borderColor": "rgba(0,0,0,0.05)",
        "borderDash": [
          10,
          10
        ],
        "speechSynthesis": true,
        "display": true
      }
    },
    "x": {
      "grid": {
        "color": "#fff",
        "borderDash": [
          2,
          2
        ],
        "borderColor": "rgba(0,0,0,0.05)",
        "display": false
      },
      "display": true
    }
  },
  "plugins": {
    "legend": {
      "display": false
    },
    "tooltip": {
      "backgroundColor": "#fff",
      "cursor": "pointer",
      "bodyColor": "rgba(0, 0, 0, 1)",
      "titleAlign": "left",
      "bodyAlign": "left",
      "borderColor": "rgba(0, 0, 0, 0.05)",
      "borderWidth": 3,
      "displayColors": true,
      "boxHeight": 0,
      "boxWidth": 0,
      "yAlign": "top",
      "usePointStyle": true,
      "callbacks": {
        labelPointStyle: (context) => {
          return {
            pointStyle: "rectangle",
            rotation: 0,
            cursor: "pointer",
          };
        },
      },
    }
  }
}

const getFullGraphData = (dataPointsArray) => ({
  "labels": [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec"
  ],
  "backgroundColor": "#fff",
  "datasets": [
    {
      "label": "all",
      data: dataPointsArray,
      "fill": true,
      "color": "#f00",
      "borderColor": "#3e5ea9",
      "pointBackgroundColor": "#3e5ea9",
      "pointBorderColor": "#fff",
      "pointRadius": 2,
      "pointHoverRadius": 2,
      "pointBorderWidth": 2,
      "tension": 0.5,
      backgroundColor: (context) => {
        const ctx = context.chart.ctx;

        const gradient = ctx.createLinearGradient(0, 0, 0, 330);
        gradient.addColorStop(0, "rgba(62, 94, 209, .05)");
        gradient.addColorStop(1, "rgba(255,255,255,0.3)");
        return gradient;
      },
    }
  ]
})

export default MicroInsuranceLineChart;